<template>
  <div>
    <!-- container_ekmtc -->
    <win-layer-pop>
      <component
        ref="dext"
        v-if="customComponent"
        :is="customComponent"
        @close="closePoup"
        :parent-info="popupParams"
      />
    </win-layer-pop>

    <button class="button blue lg mt10 layer_open" @click="openPopup('TransCompanyPop')">팝업1</button>
    &nbsp;
    <button class="button blue lg mt10 layer_open" @click="openPopup('PickUpSearchPop')">팝업2</button>
    &nbsp;
    <!--    <div>-->
    <!--      <div class="content_box">-->
    <!--        <h2 class="content_title">파일정보</h2>-->
    <!--        <table class="tbl_row mt10">-->
    <!--          <colgroup>-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--          </colgroup>-->
    <!--          <tbody v-if="uploadParams.length > 0">-->
    <!--            <template v-for="file in uploadParams">-->
    <!--              <tr :key="file.guid" style="margin-bottom: 10px">-->
    <!--                <th scope="row">파일명</th>-->
    <!--                <td>{{ file.originalName }}</td>-->
    <!--                <th scope="row">사이즈</th>-->
    <!--                <td>{{ file.size }}</td>-->
    <!--                <th scope="row">DB FileId</th>-->
    <!--                <td>{{ file.fileId }}</td>-->
    <!--                <th scope="row">custom category</th>-->
    <!--                <td>{{ file.category }}</td>-->
    <!--                <th scope="row">새파일</th>-->
    <!--                <td> {{ file.new ? '새파일' : '기존파일' }} </td>-->
    <!--              </tr>-->
    <!--            </template>-->
    <!--          </tbody>-->
    <!--        </table>-->
    <!--        <table class="tbl_row mt10">-->
    <!--          <colgroup>-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--          </colgroup>-->
    <!--          <tbody v-if="uploadParams1.length > 0">-->
    <!--            <template v-for="file in uploadParams1">-->
    <!--              <tr :key="file.guid" style="margin-bottom: 10px">-->
    <!--                <th scope="row">파일명</th>-->
    <!--                <td>{{ file.originalName }}</td>-->
    <!--                <th scope="row">사이즈</th>-->
    <!--                <td>{{ file.size }}</td>-->
    <!--                <th scope="row">DB FileId</th>-->
    <!--                <td>{{ file.fileId }}</td>-->
    <!--                <th scope="row">custom category</th>-->
    <!--                <td>{{ file.category }}</td>-->
    <!--                <th scope="row">새파일</th>-->
    <!--                <td> {{ file.new ? '새파일' : '기존파일' }} </td>-->
    <!--              </tr>-->
    <!--            </template>-->
    <!--          </tbody>-->
    <!--        </table>-->
    <!--        <table class="tbl_row mt10">-->
    <!--          <colgroup>-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--          </colgroup>-->
    <!--          <tbody v-if="uploadParams2.length > 0">-->
    <!--            <template v-for="file in uploadParams2">-->
    <!--              <tr :key="file.guid" style="margin-bottom: 10px">-->
    <!--                <th scope="row">파일명</th>-->
    <!--                <td>{{ file.originalName }}</td>-->
    <!--                <th scope="row">사이즈</th>-->
    <!--                <td>{{ file.size }}</td>-->
    <!--                <th scope="row">DB FileId</th>-->
    <!--                <td>{{ file.fileId }}</td>-->
    <!--                <th scope="row">custom category</th>-->
    <!--                <td>{{ file.category }}</td>-->
    <!--                <th scope="row">새파일</th>-->
    <!--                <td> {{ file.new ? '새파일' : '기존파일' }} </td>-->
    <!--              </tr>-->
    <!--            </template>-->
    <!--          </tbody>-->
    <!--        </table>-->
    <!--        <table class="tbl_row mt10">-->
    <!--          <colgroup>-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--            <col style="width:10%">-->
    <!--          </colgroup>-->
    <!--          <tbody v-if="uploadParams3.length > 0">-->
    <!--            <template v-for="file in uploadParams3">-->
    <!--              <tr :key="file.guid" style="margin-bottom: 10px">-->
    <!--                <th scope="row">파일명</th>-->
    <!--                <td>{{ file.originalName }}</td>-->
    <!--                <th scope="row">사이즈</th>-->
    <!--                <td>{{ file.size }}</td>-->
    <!--                <th scope="row">DB FileId</th>-->
    <!--                <td>{{ file.fileId }}</td>-->
    <!--                <th scope="row">custom category</th>-->
    <!--                <td>{{ file.category }}</td>-->
    <!--                <th scope="row">새파일</th>-->
    <!--                <td> {{ file.new ? '새파일' : '기존파일' }} </td>-->
    <!--              </tr>-->
    <!--            </template>-->
    <!--          </tbody>-->
    <!--        </table>-->
    <!--      </div>-->

    <!--      <table class="tbl_search">-->
    <!--        <colgroup>-->
    <!--          <col width="152px"><col width="152px"><col width="152px">-->
    <!--        </colgroup>-->
    <!--        <tbody>-->
    <!--          <tr>-->
    <!--            <td>-->
    <!--              <button class="button gray sm" @click="openPopup1('SampleDextUploader',1)">-->
    <!--                파일첨부-->
    <!--              </button>-->
    <!--            </td>-->
    <!--            <td>-->
    <!--              <button class="button gray sm" @click="openPopup1('SampleDextUploader',2)">-->
    <!--                파일첨부2-->
    <!--              </button>-->
    <!--            </td>-->
    <!--            <td>-->
    <!--              <button class="button gray sm" @click="openPopup1('SampleDextUploader',3)">-->
    <!--                파일첨부3-->
    <!--              </button>-->
    <!--            </td>-->
    <!--          </tr>-->
    <!--        </tbody>-->
    <!--      </table>-->
    <!--    </div>-->
  </div>
</template>

<script>
import VgmPop from '@/pages/trans/popup/VgmPop'

import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
// import SampleDextUploader from '@/pages/sample/SampleDextUploader'
import PickUpSearchPop from '@/pages/trans/popup/PickUpSearchPop'
import TransCompanyPop from '@/pages/trans/popup/TransCompanyPop'

export default {
    name: 'SamplePopupCall',
    components: {
      WinLayerPop,
      // SampleDextUploader,
      TransCompanyPop,
      PickUpSearchPop,
      VgmPop
    },
    data () {
      return {
        // init: false,
        customComponent: null,
        popupParams: {}
        // popupType: '',
        // uploadParams: [],
        // uploadParams1: [],
        // uploadParams2: [],
        // uploadParams3: []
      }
    },
  watch: {
    // uploadParams: {
    //   deep: true,
    //   handler (val) {
    //     console.log('@@@@@ upload = ' + JSON.stringify(val))
    //   }
    // }
    // uploadParams1: {
    //   deep: true,
    //   handler (val) {
    //     console.log('@@@@@ upload1 변경 = ' + JSON.stringify(val))
    //   }
    // },
    // uploadParams2: {
    //   deep: true,
    //   handler (val) {
    //     console.log('@@@@@ upload2 변경 = ' + JSON.stringify(val))
    //   }
    // },
    // uploadParams3: {
    //   deep: true,
    //   handler (val) {
    //     console.log('@@@@@ upload3 변경 = ' + JSON.stringify(val))
    //   }
    // }

  },
    mounted () {
      // this.initDextFileInfo()
    },
  methods: {
      // param
      // compNm : Import한 컴포넌트 명
      openPopup (compNm) {
        console.log('compNm =' + compNm)
        this.customComponent = compNm

        if (compNm === 'TransCompanyPop') {
          this.popupParams = {
            SrPickUpPlaceData: {
              selectRow: '0',
              cntrSize: '',
              cntrType: ''
            }
          }
        } else if (compNm === 'SampleDextUploader') {
          // console.log('test')
          // console.log('this.popupParams = ', this.popupParams)
          // this.popupParams = this.uploadParams
        } else {
          this.popupParams = {}
        }
        // 팝업 후처리
        // $('.popup_dim').fadeIn()
        // $('body,html').css('overflow', 'hidden')
        this.$ekmtcCommon.layerOpen()
      },
    // openPopup1 (compNm, id) {
    //   console.log('compNm =' + compNm)
    //   this.customComponent = compNm
    //   if (id === 1) {
    //     this.popupParams = this.uploadParams1
    //   } else if (id === 2) {
    //     this.popupParams = this.uploadParams2
    //   } else if (id === 3) {
    //     this.popupParams = this.uploadParams3
    //   } else {
    //     this.popupParams = this.uploadParams
    //   }
    //
    //   this.popupType = id
    //   // this.$refs.dext.setFileInfo(this.popupParams)
    //   // let $vm = this
    //   // this.$nextTick(function () {
    //   //   console.log($vm.$refs.dext)
    //   //   $vm.$refs.dext.setFileInfo(this.popupParams)
    //   // })
    //
    //   this.$ekmtcCommon.layerOpen()
    //   let $vm = this
    //   this.$nextTick(function () {
    //     $vm.$refs.dext.setFileInfo($vm.popupParams)
    //   })
    // },
      closePoup () {
        console.log('close')
        // $('.popup_dim').fadeOut()
        // $('body,html').removeAttr('style')
        this.$ekmtcCommon.layerClose()
      }
      // initDextFileInfo () {
      //   this.uploadParams1 = [{ guid: '21915747-F71E-930A-D23F-FD1689A1A8E6', originalName: '스크린샷 2021-08-03 오후 3.22.04.png', size: '16958', fileId: 'http://dev-api.ekmtc.com/common/commons/tempfile?fileId=663&fileSeq=1', status: 'complete', category: 'value1' }, { guid: '129C42D8-0F13-AE72-2D32-85D94A8393FC', originalName: '스크린샷 2021-08-04 오후 1.15.45.png', size: '84930', fileId: 'http://dev-api.ekmtc.com/common/commons/tempfile?fileId=664&fileSeq=1', status: 'complete', category: 'value2' }]
      //   this.uploadParams2 = [{ guid: '21915747-F71E-930A-D23F-FD1689A1A8E6', originalName: '스크린샷 2021-08-03 오후 3.22.04.png', size: '16958', fileId: 'http://dev-api.ekmtc.com/common/commons/tempfile?fileId=663&fileSeq=1', status: 'complete', category: 'value1' }, { guid: '129C42D8-0F13-AE72-2D32-85D94A8393FC', originalName: '스크린샷 2021-08-04 오후 1.15.45.png', size: '84930', fileId: 'http://dev-api.ekmtc.com/common/commons/tempfile?fileId=664&fileSeq=1', status: 'complete', category: 'value2' }]
      // },
      // setDextFileInfo (param, type) {
      //   console.log('setDextFileInfo param = ' + JSON.stringify(param))
      //   console.log('type = ' + type)
      //   if (type === 1) {
      //     this.uploadParams1 = param
      //
      //     console.log('@@@ uploadParams1 = ', JSON.stringify(this.uploadParams1))
      //   } else if (type === 2) {
      //     this.uploadParams2 = param
      //     console.log('@@@ uploadParams2 = ', JSON.stringify(this.uploadParams2))
      //   } else if (type === 3) {
      //     this.uploadParams3 = param
      //
      //     console.log('@@@ uploadParams3 = ', JSON.stringify(this.uploadParams3))
      //   }
      //   this.popupParams = []
      //   // this.uploadParams = []
      // }

    }
}
</script>
