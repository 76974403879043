var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                ref: "dext",
                tag: "component",
                attrs: { "parent-info": _vm.popupParams },
                on: { close: _vm.closePoup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "button",
        {
          staticClass: "button blue lg mt10 layer_open",
          on: {
            click: function ($event) {
              return _vm.openPopup("TransCompanyPop")
            },
          },
        },
        [_vm._v("팝업1")]
      ),
      _vm._v("   "),
      _c(
        "button",
        {
          staticClass: "button blue lg mt10 layer_open",
          on: {
            click: function ($event) {
              return _vm.openPopup("PickUpSearchPop")
            },
          },
        },
        [_vm._v("팝업2")]
      ),
      _vm._v("   "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }